import React, { useEffect, useState, useRef, useContext } from "react";
import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { makeStyles } from '@mui/styles';

import "./header.css";
import { logout } from "../login/loginService";
import { alertContext, englishContext } from "../../contexts";
import { allNotsViewd } from "../../utils/notificationUtils";
import MyURL from "../../utils/MyUrl";
import { fetchData } from "../../utils/http.js";

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
  menu: {
    zIndex: '333333',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [topics, setTopics] = useState([]);
  const [topicsList, setTopicsList] = useState([]);
  const matches = useMediaQuery("(min-width:1150px)");
  const matchesEng = useMediaQuery("(min-width:1335px)");
  const topicsRef = useRef(null);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { isEnglish, setIsEnglish } = useContext(englishContext);
  const { isViewd, setIsViewd } = useContext(alertContext);
  const myUrl = new MyURL();
  const engText = matches ? "English" : "En";
  const hebText = matches ? "עברית" : "עב";
  const location = useLocation();

  const handleHomeClick = (e) => {
    if (location.pathname === '/') {
      e.preventDefault();
      return;
    }
  };
  const toggleTopics = () => {
    if (topicsRef.current.style.display === "none") {
      topicsRef.current.style.display = "block";
    } else {
      topicsRef.current.style.display = "none";
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.key === "Tab" ||
      event.key === "Shift" ||
      event.target.id === "topic"
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    fetchData("/topic/get-topic-titles").then((res) => {
      setTopics(res.data);
    });
    const english = localStorage.getItem("isEnglish");
    if (english) {
      setIsEnglish(true);
    }
    if (window.location.search.includes("eng=true")) {
      setIsEnglish(true);
      localStorage.setItem("isEnglish", JSON.stringify(true));
      localStorage.setItem("lang", "e");
      myUrl.deleteParam("eng");
      myUrl.setURL();
    }
    fetchData("/subscription/get-new-notifications-ids").then((res) => {
      const allViewd = allNotsViewd(res.data);
      setIsViewd(allViewd);
    });
  }, []);

  useEffect(() => {
    const TL = topics?.map((topic) => (
      <MenuItem key={topic} onClick={handleClose}>
        <Link className="link topic-link" to={`/topics?topic=${topic}`}>
          {topic}
        </Link>
      </MenuItem>
    ));
    setTopicsList(TL);
  }, [topics]);

  const toggleLang = () => {
    if (isEnglish) {
      localStorage.removeItem("isEnglish");
      setIsEnglish(false);
    } else {
      localStorage.setItem("isEnglish", JSON.stringify(true));
      setIsEnglish(true);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    document.querySelector("body").style.overflowY = "hidden";
    document.querySelector("body").style.paddingRight = "7px";
    if (!isEnglish) document.querySelector(".img-top").style.right = "7px";
  };

  const handleClose = () => {
    setAnchorEl(null);
    document.querySelector("body").style.overflowY = "scroll";
    document.querySelector("body").style.paddingRight = "0";
    if (!isEnglish) document.querySelector(".img-top").style.right = "unset";
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button>
          <Typography className="bar-link" variant="h5">
            <Link className="link" to="/about">
              {isEnglish ? "About" : "אודות"}
            </Link>
          </Typography>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button>
          <Typography className="bar-link" variant="h5">
            <Link className="link" to="/search">
              {isEnglish ? "Search Archive" : "חיפוש בארכיון"}
            </Link>
          </Typography>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button>
          <Typography className="bar-link" variant="h5">
            <Link className="link" to="/dedication">
              {isEnglish ? "Dedications" : "הקדשת שיחות"}
            </Link>
          </Typography>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button>
          <Typography className="bar-link" variant="h5">
            <Link className="link" to="/download">
              {isEnglish ? "Downloads" : "הורדות"}
            </Link>
          </Typography>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button>
          <Typography className="bar-link" variant="h5">
            <Link className="link" to="/other-options">
              {isEnglish ? "Other Options" : "אפשרויות נוספות"}
            </Link>
          </Typography>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button>
          <Typography className="bar-link" variant="h5">
            <Box id="topic" className="link" onClick={toggleTopics}>
              {isEnglish ? "Subjects" : "נושאים"}
            </Box>
          </Typography>
        </ListItem>
      </List>
      <List className="topic-list" style={{ display: "none" }} en ref={topicsRef}>
        {topicsList}
      </List>
    </div>
  );

  return (
    <div className={isEnglish ? "ltr" : "rtl"}>
      <div className="img-top"></div>

      <AppBar className="nav-bar" position="static">
        <Toolbar className="toolbar">
          <Typography variant="h4" className={classes.title}>
            <div className="bsd">{isEnglish ? 'BS"D' : 'בס"ד'}</div>
            <Link className="link title" to='/' onClick={handleHomeClick}>
              {isEnglish ? "The Daily Sicha" : "השיחה היומית"}
            </Link>
          </Typography>
          <Link className="link" to={`/notification`}>
            <IconButton className="notification-btn">
              <NotificationsNoneIcon />
              {isViewd ? "" : <Box className="alert-dot">&#x2022;</Box>}
            </IconButton>
          </Link>
          {((!isEnglish && matches) || (isEnglish && matchesEng)) && (
            <>
              {props.auth && (
                <>
                  <Typography className="bar-link" variant="h5">
                    <Link className="link" to="/admin">
                      ניהול
                    </Link>
                  </Typography>
                  <Typography className="bar-link" variant="h5">
                    <Link
                      className="link"
                      to="/"
                      onClick={() => logout(props.setAuth)}
                    >
                      התנתק
                    </Link>
                  </Typography>
                </>
              )}
              <Typography className="bar-link" variant="h5">
                <Link className="link" to="/other-options">
                  {isEnglish ? "Other Options" : "אפשרויות נוספות"}
                </Link>
              </Typography>
              |
              <Typography className="bar-link" variant="h5">
                <Link className="link" to="/search">
                  {isEnglish ? "Search" : "חיפוש"}
                </Link>
              </Typography>
              |
              <Typography className="bar-link" variant="h5">
                <Box className="link" onClick={handleClick}>
                  {isEnglish ? "Subjects" : "נושאים"}
                </Box>
                <Menu
                  disableScrollLock={true}
                  className={classes.menu + " topic-menu"}
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  {topicsList}
                </Menu>
              </Typography>
              |
              <Typography className="bar-link" variant="h5">
                <Link className="link" to="/download">
                  <Box display="flex" alignItems="flex-end">
                    {isEnglish ? "Downloads" : "הורדות"}
                  </Box>
                </Link>
              </Typography>
              |
              <Typography className="bar-link" variant="h5">
                <Link className="link" to="/dedication">
                  {isEnglish ? "Dedications" : "הקדשות"}
                </Link>
              </Typography>
              |
              <Typography className="bar-link" variant="h5">
                <Link className="link" to="/about">
                  <Box display="flex" alignItems="flex-end">
                    {isEnglish ? "About" : "אודות"}
                  </Box>
                </Link>
              </Typography>
            </>
          )}
          {((!isEnglish && !matches) || (isEnglish && !matchesEng)) && (
            <>
              <Button
                className="lang-btn-main"
                variant="outlined"
                onClick={toggleLang}
              >
                {isEnglish ? hebText : engText}
              </Button>
              <IconButton
                onClick={toggleDrawer(isEnglish ? 'right': "left", true)}
                className="menu-icon"
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon className="menu-icon" />
              </IconButton>
              <Drawer
                className={["menu-mobile ", isEnglish ? 'ltr': "rtl"]}
                anchor={isEnglish ? 'right': "left"}
                open={isEnglish ? state["right"]: state["left"]}
                onClose={toggleDrawer(isEnglish ? 'right': "left", false)}
              >
                {list(isEnglish ? 'right': "left")}
              </Drawer>
            </>
          )}

          {((!isEnglish && matches) || (isEnglish && matchesEng)) && (
            <Button
              className="lang-btn-main"
              variant="outlined"
              onClick={toggleLang}
            >
              {isEnglish ? hebText : engText}
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
